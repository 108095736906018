<template>
  <div>
    <b-modal ref="my-modal" centered hide-footer title="Metode kalkulasi waktu shalat">
      <div class="d-block text-center">
        <p>
          Dalam perhitungan waktu shalat, kami mengikuti metode 
          <b-badge
            v-b-tooltip.hover
            title="Organ departementasi NU yang berfungsi sebagai pelaksana kebijakan–kebijakan NU dalam ranah falakiyah, yaitu ilmu astronomi yang ditujukan bagi pelaksanaan aspek–aspek ibadah Umat Islam."
            variant="success"
            >Falakiyah NU</b-badge
          >
          , dengan menggunakan tinggi sudut posisi matahari untuk Subuh -20° dan Isya -18°.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
  },
  mounted() {
    this.showModal();
  },
};
</script>