<template>
  <div>
    <b-skeleton-table
      :rows="6"
      :columns="2"
      :table-props="{ bordered: true, striped: true }"
      v-if="isLoading"
    ></b-skeleton-table>
    <b-list-group v-if="!isLoading">
      <b-list-group-item
        :variant="nextPrayer === 'Subuh' ? 'dark' : ''"
        class="d-flex align-items-center"
      >
        <b-avatar
          :variant="nextPrayer === 'Subuh' ? 'success' : 'primary'"
          icon="brightness-alt-high"
          class="mr-3"
        ></b-avatar>
        <span class="mr-auto">Subuh</span>
        <h2>{{ prayers.fajr }}</h2>
      </b-list-group-item>
      <b-list-group-item
        :variant="nextPrayer === 'Zuhur' ? 'dark' : ''"
        class="d-flex align-items-center"
      >
        <b-avatar
          :variant="nextPrayer === 'Zuhur' ? 'success' : 'primary'"
          icon="brightness-high"
          class="mr-3"
        ></b-avatar>
        <span class="mr-auto">Zuhur</span>
        <h2>{{ prayers.duhr }}</h2>
      </b-list-group-item>
      <b-list-group-item
        :variant="nextPrayer === 'Ashar' ? 'dark' : ''"
        class="d-flex align-items-center"
      >
        <b-avatar
          :variant="nextPrayer === 'Ashar' ? 'success' : 'primary'"
          icon="brightness-low"
          class="mr-3"
        >
        </b-avatar>
        <span class="mr-auto">Ashar</span>
        <h2>{{ prayers.asr }}</h2>
      </b-list-group-item>
      <b-list-group-item
        :variant="nextPrayer === 'Maghrib' ? 'dark' : ''"
        class="d-flex align-items-center"
      >
        <b-avatar
          :variant="nextPrayer === 'Maghrib' ? 'success' : 'primary'"
          icon="brightness-alt-low-fill"
          class="mr-3"
        ></b-avatar>
        <span class="mr-auto">Maghrib</span>
        <h2>{{ prayers.maghrib }}</h2>
      </b-list-group-item>
      <b-list-group-item
        :variant="nextPrayer === 'Isya' ? 'dark' : ''"
        class="d-flex align-items-center"
      >
        <b-avatar
          :variant="nextPrayer === 'Isya' ? 'success' : 'primary'"
          icon="brightness-low-fill"
          class="mr-3"
        ></b-avatar>
        <span class="mr-auto">Isya</span>
        <h2>{{ prayers.isha }}</h2>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["waktu", "city"],
  data() {
    return {
      isLoading: false,
      prayers: {
        fajr: "",
        duhr: "",
        asr: "",
        maghrib: "",
        isha: "",
      },
      nextPrayer: "",
    };
  },
  created() {
    this.fetchData(this.waktu, this.city);
  },
  methods: {
    fetchData(waktu, city) {
      this.isLoading = true;
      axios
        .get(
          // custom method according to falakiyah nu
          // methodSettings=20,null,18 => 20 for subuh and 18 for isya
          // tune=2 => use 2 minutes ihtiyath
          `${process.env.VUE_APP_ROOT_API}?waktu=${waktu}&kabko=${city}`
        )
        .then((res) => {
          this.getTodaysPrayerTimes({ ...res });
          this.$emit("apiResponse", res, this.isLoading, this.nextPrayer);
          this.prayers.fajr = res["data"]["data"]["timings"].Fajr;
          this.prayers.duhr = res["data"]["data"]["timings"].Dhuhr;
          this.prayers.asr = res["data"]["data"]["timings"].Asr;
          this.prayers.maghrib = res["data"]["data"]["timings"].Maghrib;
          this.prayers.isha = res["data"]["data"]["timings"].Isha;
          this.isLoading = false;
        })
        .catch((err) => {
          this.$emit("failedFetching", err);
          setTimeout(() => {
            this.fetchData(this.waktu, this.city);
          }, 5000);
        });
    },
    getTodaysPrayerTimes({
      data: {
        data: { timings },
      },
    }) {
      delete timings.Imsak;
      delete timings.Midnight;
      delete timings.Sunrise;
      delete timings.Sunset;
      const timingsTolistOrdered = [
        { salat: "Subuh", time: timings.Fajr },
        { salat: "Zuhur", time: timings.Dhuhr },
        { salat: "Ashar", time: timings.Asr },
        { salat: "Maghrib", time: timings.Maghrib },
        { salat: "Isya", time: timings.Isha },
      ];
      const nextPrayers = [];
      timingsTolistOrdered.forEach((el) => {
        const [hours, minute] = el.time.split(":");
        if (new Date().getHours() < parseInt(hours)) {
          nextPrayers.push(el.salat);
          // console.log("next prayer is " + el.salat);
          // console.log("next prayer is " + el.time);
        } else if (new Date().getHours() == parseInt(hours)) {
          if (new Date().getMinutes() <= parseInt(minute)) {
            nextPrayers.push(el.salat);
          }
        }
      });
      if (nextPrayers[0] == undefined) nextPrayers.push("Subuh");
      this.nextPrayer = nextPrayers[0];
      this.$emit(
        "currentPrayerNameAndTime",
        timingsTolistOrdered.find((salat) => this.nextPrayer === salat.salat)
      );
    },
  },
  watch: {
    city(newCity) {
      localStorage.kabko = newCity;
      this.fetchData(this.waktu, newCity);
    },
  },
};
</script>
