<template>
  <b-container class="bv-example-row">
    <b-row>
      <b-col md="6" offset-md="3">
        <b-row>
          <b-col md="6">
            <model-select
              v-model="selectedCountryByCode"
              :options="options"
            ></model-select>
          </b-col>
          <b-col md="6">
            <model-select
              @input="HandleCity"
              v-model="selectedCity"
              :options="citiesForGivenCountry"
            ></model-select>
          </b-col>
        </b-row>
        <hr />
        <b-skeleton
          v-if="!isLoading"
          animation="wave"
          height="2.5rem"
          width="100%"
        ></b-skeleton>
        <!-- </b-card> -->
        <div id="justToCenterTheAlert">
          <b-alert v-if="isLoading" show variant="success">
            {{ hijriDate }}
            <hr />
            <vue-countdown
              @end="countdownFinished"
              :time="time"
              :transform="transform"
              v-slot="{ hours, minutes, seconds }"
            >
              <span>{{ hours }} : </span>
              <span>{{ minutes }} : </span>
              {{ seconds }} menuju
              <h4>
              <b-badge
                v-b-tooltip.hover
                variant="warning"
                >{{ nextPrayer }}.
              </b-badge>
            </h4>
            </vue-countdown>
          </b-alert>
        </div>
        <!-- </b-row> -->
        <hr />
        <RetryAlert :retry="retryRequest" />
        <b-row>
          <b-col>
            <Prayers
              @failedFetching="retryFetching"
              @apiResponse="loadApiResponseFromChild"
              @currentPrayerNameAndTime="feedCountDown"
              :waktu="waktuSholat"
              :city="selectedCity"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as data from "../helpers/countries";
import cities from "../helpers/cities.json";
import Prayers from "./Prayers";
import RetryAlert from "./Alert";
import { ModelSelect } from "vue-search-select";

export default {
  components: {
    Prayers,
    RetryAlert,
    ModelSelect,
  },
  citiesJson: cities,
  data() {
    return {
      selectedCountryByCode: "ID",
      selectedCountryFullName: "",
      selectedCity: "",
      waktuSholat: "",
      options: data.countries,
      citiesForGivenCountry: [],
      retryRequest: false,
      hijriDate: "",
      isLoading: false,
      nextPrayer: "",
      leftMinutesToNextPrayer: 0,
      sendRequestAgainWhenCountDownFinished: false,
    };
  },
  created() {
    this.selectedCountryFullName = 'Indonesia';
    this.loadCities();
    localStorage.kabko? this.selectedCity = localStorage.kabko : this.selectedCity = "Jakarta";
    // get time in dd-mm-yyyy for this.waktuSholat
    const date = new Date();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();
    this.waktuSholat = dd + '-' + mm + '-' + yyyy;
  },
  methods: {
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        // Adds leading zero
        const digits = value < 10 ? `0${value}` : value;
        props[key] = `${digits}`;
      });
      return props;
    },
    loadCities() {
      this.citiesForGivenCountry = [];
      this.loadCitiesForGivenCountry.forEach((city) => {
        this.citiesForGivenCountry.push({
          text: city.name,
          value: city.name,
        });
      });
      this.selectedCity = this.citiesForGivenCountry[1].value;
    },
    HandleCity(city) {
      this.selectedCity = city;
    },
    retryFetching() {
      //here i receive the error message as a paramer but because i dont need it i remove it but it can be added
      this.retryRequest = !this.retryRequest;
    },
    loadApiResponseFromChild(response, isLoading, nextPrayer) {
      this.hijriDate = `${{ Monday: 'Senin', Tuesday: 'Selasa', Wednesday: 'Rabu', Thursday: 'Kamis', Friday: 'Jumat', Saturday: 'Sabtu', Sunday: 'Minggu' }[response["data"]["data"]["date"]["gregorian"]["weekday"].en]}, ${response["data"]["data"]["date"]["hijri"].day} ${response["data"]["data"]["date"]["hijri"].month.en} ${response["data"]["data"]["date"]["hijri"].year}, ${response["data"]["data"]["date"].readable}`;
      this.isLoading = isLoading;
      this.nextPrayer = nextPrayer;
    },
    feedCountDown(nextSalat) {
      const [hours, minutes] = nextSalat.time.split(":");
      const nextPrayerTime = new Date();
      nextPrayerTime.setHours(hours);
      nextPrayerTime.setMinutes(minutes);
      const dateNow = new Date();

      let diff = (nextPrayerTime.getTime() - dateNow.getTime()) / 1000;
      diff /= 60;
      this.leftMinutesToNextPrayer = Math.abs(Math.round(diff));
    },
    countdownFinished() {
      window.location.reload();
    },
  },
  computed: {
    loadCitiesForGivenCountry() {
      return this.$options.citiesJson.filter(
        (obj) => obj.country === this.selectedCountryFullName
      );
    },
    time() {
      return this.leftMinutesToNextPrayer * 60 * 1000;
    },
  },
};
</script>

<style scoped>
#justToCenterTheAlert {
  text-align: center;
}
</style>